import React, { Component } from 'react'
import Slick from 'react-slick'
import Tab, { TabPane } from '../tab'
import './cars.scss'

/**
 * @param {object} props
 * @param {array} props.cities
 */
export default class Cars extends Component {
  static defaultProps = {
    cities: [],
  }
  desktopCars(city) {
    const { isMobile } = this.props
    return (
      <Slick
        dots
        slidesToShow={1}
        nextArrow={<SlickNextArrow isMobile={isMobile} />}
        prevArrow={<SlickPrevArrow isMobile={isMobile} />}
      >
        {chunkArrayInGroups(city.cars, 8).map((cars, i) => (
          <div key={`PAGE-${i}`}>
            <div className="row cars">
              {cars.map(car => (
                <div key={car.id} className="col-md-3 flex-center">
                  <Car
                    onClick={() =>
                      this.props.onSelect({ city_id: city.id, car_id: car.id })
                    }
                    {...car}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slick>
    )
  }

  mobileCars(city) {
    const { isMobile } = this.props
    return (
      <div className="cars">
        <Slick
          ref={ref => (this.slick = ref)}
          swipeToSlide
          variableWidth
          slidesToShow={1}
          slidesToScroll={1}
          initialSlide={0}
          arrows={false}
        >
          {city.cars.map((car, i) => (
            <Car
              onClick={() =>
                this.props.onSelect({ city_id: city.id, car_id: car.id })
              }
              key={car.id}
              {...car}
            />
          ))}
        </Slick>
        <div className="arrows-container">
          <SlickPrevArrow
            onClick={() => this.slick.slickPrev()}
            isMobile={isMobile}
          />
          <SlickNextArrow
            onClick={() => this.slick.slickNext()}
            isMobile={isMobile}
          />
        </div>
      </div>
    )
  }
  get cities() {
    const { cities, isMobile } = this.props

    return isMobile
      ? cities.filter((_, i) => i < 3)
      : cities.filter((_, i) => i < 6)
  }
  render() {
    const { cities, isMobile } = this.props
    return (
      <div className="car-with-driver__cars">
        <h2>รุ่นรถที่พร้อมให้บริการ เช่ารถ พร้อมคนขับ</h2>
        <Tab isMobile={isMobile}>
          {cities.map(city => (
            <TabPane key={city.name.th} title={city.name.th}>
              <div className="container">
                {isMobile ? this.mobileCars(city) : this.desktopCars(city)}
              </div>
            </TabPane>
          ))}
        </Tab>
      </div>
    )
  }
}

function chunkArrayInGroups(arr, size) {
  const myArray = []
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size))
  }
  return myArray
}
/**
 * @param {object} props
 * @param {object of th, en} props.title
 * @param {number} props.seats
 * @param {number} props.price_per_day
 * @param {image src} props.image
 * @param {function} props.onClick
 */
const Car = ({
  title = { th: '', en: '' },
  seats = 4,
  price_per_day = 0,
  image = '',
  onClick = () => {},
}) => (
  <div className="car">
    <img src={image} alt={title.th} />
    <h2>{title.th}</h2>
    <sub>
      <i className="flaticon-user" />
      {seats} ที่นั่ง
    </sub>
    <div>เริ่มต้น {price_per_day.toLocaleString()} บาท/วัน</div>
    <button onClick={onClick} className="btn btn-primary">
      จองคันนี้
    </button>
  </div>
)

const SlickPrevArrow = props => {
  const { onClick } = props
  return (
    <div
      className={`button ${
        props.isMobile ? 'button--left-mobile' : 'button--left-slick'
      } `}
      onClick={onClick}
    >
      <i className="flaticon-play-button" />
    </div>
  )
}

const SlickNextArrow = props => {
  const { onClick } = props
  return (
    <div
      className={`button ${
        props.isMobile ? 'button--right-mobile' : 'button--right-slick'
      } `}
      onClick={onClick}
    >
      <i className="flaticon-play-button" />
    </div>
  )
}
