import React, { Component } from 'react'
import BackgroundImage from 'gatsby-background-image'
import { graphql, StaticQuery } from 'gatsby'
import axios from 'axios'
import ReactGA from 'react-ga'
import Form from './form'
import Success from './success'
import Layout from '../layout'
import SEO from '../seo'
import Cars from './cars'
import Panels from '../panels'
import './index.scss'
import { API_CAR_WITH_DRIVER, WEB_LEGACY_LINK } from '../../../config'
import nineHoursIcon from '../../../static/assets/car-with-driver/9hours.svg'
import guardIcon from '../../../static/assets/car-with-driver/guard.svg'
import driverIcon from '../../../static/assets/car-with-driver/driver.svg'
import twentyFourHoursIcon from '../../../static/assets/car-with-driver/24hours.svg'

axios.defaults.baseURL = API_CAR_WITH_DRIVER
const Wrapper = props => (
  <StaticQuery
    query={graphql`
      query {
        carWithDriver: allMarkdownRemark(
          filter: { fields: { collection: { eq: "carwithdriver" } } }
        ) {
          edges {
            node {
              frontmatter {
                header_th
                header_en
                title_th
                title_en
                keywords_th
                keywords_en
                description_th
                description_en
                sub_title_th
                sub_title_en
                seo_header_th
                seo_header_en
                panels {
                  title_th
                  title_en
                  content_th
                  content_en
                  show_google_faq
                }
              }
            }
          }
        }
        headerDesktop: file(
          relativePath: { eq: "assets/car-with-driver-header.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        easy: file(relativePath: { eq: "assets/car-with-driver-easy.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <CarWithDriver {...props} data={data} />}
  />
)

class CarWithDriver extends Component {
  state = {
    isMobile: true,
    cities: [],
    isSuccess: false,
    response: {},
  }

  componentDidMount() {
    this.loadCities()
    const initialWidth = window.innerWidth
    this.setState({ isMobile: initialWidth < 768 ? true : false })

    let widthChecker = window.matchMedia('(max-width: 768px)')
    widthChecker.addListener(e => {
      if (e.matches) {
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    })
  }
  loadCities() {
    axios
      .get('/api/cities', {
        params: {
          with_cars: true,
        },
      })
      .then(({ data }) => this.setState({ cities: data }))
  }
  onSubmit = (data, callback) => {
    axios
      .post('/api/bookings', data)
      .then(({ data }) => {
        ReactGA.event({
          category: 'car_with_driver',
          action: 'click',
          label: 'request_car_with_driver',
        })

        window.fbq('track', 'Lead', {
          currency: 'THB',
          value: this.form.state.pricing.price_per_day,
          epic: 'car-with-driver',
        })

        this.setState({ response: data, isSuccess: true })
        this.scrollToForm()
        callback()
      })
      .catch(error => {
        console.log(error)
      })
  }

  scrollToForm = () => {
    window.scroll({
      top: this.formHeader.offsetTop,
      left: 0,
      behavior: 'smooth',
    })
  }
  panels = (carWithDriver, prefix) => {
    const panelsWithLocale = carWithDriver.panels.reduce((p, c) => {
      let panelObj = {}
      Object.keys(c).map(key => {
        if (key.includes(prefix)) {
          panelObj[key.split('_')[0]] = c[key]
        }
      })
      panelObj['show_google_faq'] = c.show_google_faq
      return p.concat([panelObj])
    }, [])

    return panelsWithLocale
  }
  render() {
    const { data, prefix } = this.props
    const { isMobile } = this.state
    const backgroundFluid = data.headerDesktop.childImageSharp.fluid
    const easyFluid = data.easy.childImageSharp.fluid
    const carWithDriver = data.carWithDriver.edges[0].node.frontmatter

    return (
      <Layout
        prefix={prefix}
        isMobile={isMobile}
        menuStyle={
          isMobile ? null : { position: 'absolute', width: '100%', zIndex: 10 }
        }
      >
        <SEO
          title={carWithDriver[`header_${prefix}`]}
          prefix={prefix}
          path="car-with-driver"
          canonical={`${WEB_LEGACY_LINK}/${prefix}/car-with-driver`}
          description={carWithDriver[`description_${prefix}`]}
          keywords={carWithDriver[`keywords_${prefix}`]}
        />
        <div className="car-with-driver">
          <BackgroundImage
            color="red"
            className="header"
            fluid={backgroundFluid}
          >
            <div className="container">
              <div>
                <h1>{carWithDriver[`title_${prefix}`]}</h1>
                <p>{carWithDriver[`sub_title_${prefix}`]}</p>
                <button
                  onClick={this.scrollToForm}
                  className="btn btn-primary btn-block"
                >
                  เลือกรถและเช็คราคา
                </button>
              </div>
            </div>
          </BackgroundImage>
          <ValueProps />
          <div className="borderless">
            <div className="container">
              <Cars
                isMobile={this.state.isMobile}
                onSelect={({ city_id, car_id }) => {
                  this.form.setForm({ city_id, car_id })
                  this.scrollToForm()
                }}
                cities={this.state.cities}
              />
            </div>
          </div>
          <EasyStep fluid={easyFluid} />
          <h1 ref={ref => (this.formHeader = ref)}>จองรถเช่าพร้อมคนขับ</h1>
          <div id="car-with-driver-form" className="container">
            <div>
              <div className={!this.state.isSuccess ? 'hide' : 'show'}>
                <Success bookingId={this.state.response.id} />
              </div>
              <div className={this.state.isSuccess ? 'hide' : 'show'}>
                <Form
                  onSubmit={this.onSubmit}
                  ref={ref => (this.form = ref)}
                  cities={this.state.cities}
                />
              </div>
            </div>
          </div>
          <Faq
            header={carWithDriver[`seo_header_${prefix}`]}
            panels={this.panels(carWithDriver, prefix)}
          />
        </div>
      </Layout>
    )
  }
}

const valueProps = [
  {
    icon: guardIcon,
    text: 'รถเช่าคุณภาพมาตรฐาน พร้อมให้บริการ',
  },
  {
    icon: driverIcon,
    text: 'พนักงานขับรถ มืออาชีพ',
  },
  {
    icon: nineHoursIcon,
    text: 'ราคารวมบริการ 9ชั่วโมง/วัน',
  },
  {
    icon: twentyFourHoursIcon,
    text: 'มีเจ้าหน้าที่พร้อมดูแลคุณ ตลอดการเดินทาง',
  },
]
const ValueProps = () => (
  <div className="value-props__main">
    <div className="container">
      {valueProps.map(item => (
        <div key={item.text}>
          <img src={item.icon} alt={item.text} />
          <p>{item.text}</p>
        </div>
      ))}
    </div>
  </div>
)

const steps = [
  'เลือกจังหวัด - วันเวลา ใช้รถ',
  'เลือกประเภทรถ - กดจอง',
  'รอร้านรถเช่าติดต่อกลับ',
  'ยืนยันการจอง',
]
const EasyStep = ({ fluid }) => (
  <BackgroundImage className="easy-step" fluid={fluid}>
    <div className="container">
      <div className="wrapper">
        <h3>ขั้นตอนง่ายๆในการใช้บริการเช่ารถ พร้อมคนขับ</h3>
        <div>
          {steps.map((step, index) => (
            <React.Fragment key={step}>
              <div className="content">
                <span
                  className={`circle-bullet ${index === 0 ? 'active' : ''}`}
                >
                  {index + 1}
                </span>
                <p>{step}</p>
              </div>
              {index !== 3 && <div className="verticle__separator" />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  </BackgroundImage>
)

const Faq = ({ header, panels }) => (
  <Panels noHeader panels={panels} header={header} />
)

export default Wrapper
