import React, { Component } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import propTypes from 'prop-types'
import moment from 'moment'
import axios from 'axios'
import Modal from 'rc-dialog'
import { API_CAR_WITH_DRIVER } from '../../../config'
import MediaQuery from 'react-responsive'
import StickyBox from 'react-sticky-box'
import DatePicker from '../../legacy-components/DatePicker'
import TimePicker from '../../legacy-components/TimePicker'
import carIcon from '../../../static/assets/car-with-driver/car_sm.svg'
import driverIcon from '../../../static/assets/car-with-driver/driver_sm.svg'
import nineIcon from '../../../static/assets/car-with-driver/9hours_sm.svg'
import clickIcon from '../../../static/assets/car-with-driver/click.svg'
import './form.scss'

moment().locale('Asia/Bangkok')
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

axios.defaults.baseURL = API_CAR_WITH_DRIVER
export default class CarWithDriverForm extends Component {
  static propTypes = {
    cities: propTypes.array.isRequired,
  }
  static defaultProps = {
    prefix: 'th',
    loadCars: () => {},
    loadCities: () => {},
    onSubmit: () => {},
  }
  state = {
    pickupDateFocused: false,
    returnDateFocused: false,
    pickupTimeFocused: false,
    returnTimeFocused: false,
    pricing: {
      duration_days: 0,
      duration_hours: 0,
      price_per_day: 0,
      total_price: 0,
      pickup_datetime: moment()
        .add(1, 'days')
        .format('YYYY-MM-DD 10:00'),
      return_datetime: moment()
        .add(3, 'days')
        .format('YYYY-MM-DD 10:00'),
      city: '',
      bookability: {
        bookable: false,
        code: null,
      },
    },
    isOpenTerms: false,
    isLoadingPricing: false,
  }

  get schema() {
    return yup.object().shape({
      full_name: yup
        .string()
        .min(7, 'กรุณากรอกชื่อ - นามสกุล')
        .required('กรุณากรอกชื่อ - นามสกุล'),
      phone_number: yup
        .string()
        .min(10, 'เบอร์โทรศัพท์ไม่ถูกต้อง')
        .matches(phoneRegExp, 'เบอร์โทรศัพท์ไม่ถูกต้อง')
        .required('กรุณากรอกเบอร์โทรศัพท์'),
      email: yup
        .string()
        .email('อีเมลไม่ถูกต้อง')
        .required('กรุณากรอกอีเมล'),
      car_id: yup.string().required(),
      city_id: yup.string().required(),
      pickup_date: yup.date().required(),
      pickup_time: yup.string().required(),
      return_date: yup.date().required(),
      return_time: yup.string().required(),
    })
  }

  togglePickupDate = () =>
    this.setState({ pickupDateFocused: !this.state.pickupDateFocused })
  toggleReturnDate = () =>
    this.setState({ returnDateFocused: !this.state.returnDateFocused })
  togglePickupTime = () =>
    this.setState({ pickupTimeFocused: !this.state.pickupTimeFocused })
  toggleReturnTime = () =>
    this.setState({ returnTimeFocused: !this.state.returnTimeFocused })

  _createTime(limit = 24, item = [], i = 0) {
    if (item.length < limit * 2) {
      return this._createTime(
        limit,
        item.concat([
          { value: `${i > 9 ? i : '0' + i}:00`, label: `${i}:00` },
          { value: `${i > 9 ? i : '0' + i}:30`, label: `${i}:30` },
        ]),
        i + 1,
      )
    } else {
      return item
    }
  }
  _generateGroupTime(seed) {
    const times = seed.map(item => ({ name: item.label, value: item.value }))
    return [
      {
        data: times.slice(0, 24),
        label: 'ช่วงเวลาแนะนำ',
      },
      {
        data: times.slice(24, times.length),
        label: 'อื่นๆ',
      },
    ]
  }
  _generateTime() {
    return this._createTime()
      .splice(12, this._createTime().length)
      .concat(this._createTime().splice(0, 12))
  }
  get pickupTimeAvailable() {
    const times = this._generateTime()
    return times
  }
  setForm(state) {
    this.form.setFieldValue('city_id', state.city_id)
    this.form.setFieldValue('car_id', state.car_id)
    this.loadPricing(state)
  }
  loadPricing(option = {}) {
    const values = {
      ...this.form.state.values,
      ...option,
    }
    const pickup_datetime = `${values.pickup_date} ${values.pickup_time}`
    const return_datetime = `${values.return_date} ${values.return_time}`
    const car_id = values.car_id
    const params = {
      pickup_datetime,
      return_datetime,
      car_id,
    }
    this.setState({ isLoadingPricing: true })
    axios
      .get('/api/pricing', {
        params,
      })
      .then(({ data }) => {
        this.setState({
          pricing: {
            ...data,
            city: data.city.th,
          },
          isLoadingPricing: false,
        })
      })
  }
  onPickupDateChange = value => {
    this.form.setFieldValue('pickup_date', value.format('YYYY-MM-DD'))
    this.setState({
      pickupDateFocused: false,
    })
    if (value.isAfter(moment(this.form.state.values.return_date))) {
      this.form.setFieldValue(
        'return_date',
        value.add(1, 'days').format('YYYY-MM-DD'),
      )
      this.loadPricing({
        pickup_date: value.format('YYYY-MM-DD'),
        return_date: value.add(1, 'days').format('YYYY-MM-DD'),
      })
    } else {
      this.loadPricing({ pickup_date: value.format('YYYY-MM-DD') })
    }
  }

  errorRender(key) {
    if (!this.form) return ''
    const value = this.form.state.errors[key]
    if (!value) return ''
    if (!this.form.state.touched) return ''
    if (Object.keys(this.form.state.touched).includes(key)) {
      if (this.form.state.touched[key] === false) return ''
    } else {
      return ''
    }
    return <span style={{ color: 'red', fontSize: 14 }}>( {value} )</span>
  }

  onOpenTerms = () => {
    this.setState({ isOpenTerms: true })
  }

  onCloseTerms = () => {
    this.setState({ isOpenTerms: false })
  }

  render() {
    const { cities, prefix } = this.props
    return (
      <React.Fragment>
        <Formik
          ref={ref => (this.form = ref)}
          initialValues={{
            full_name: '',
            phone_number: '',
            email: '',
            car_id: null,
            city_id: '',
            pickup_date: moment()
              .add(1, 'days')
              .format('YYYY-MM-DD'),
            pickup_time: '10:00',
            return_date: moment()
              .add(3, 'days')
              .format('YYYY-MM-DD'),
            return_time: '10:00',
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true)
            const submitValue = {
              ...values,
              pickup_datetime: `${values.pickup_date} ${values.pickup_time}`,
              return_datetime: `${values.return_date} ${values.return_time}`,
            }
            delete submitValue.pickup_date
            delete submitValue.pickup_time
            delete submitValue.return_date
            delete submitValue.return_time
            this.props.onSubmit(submitValue, () => {
              actions.setSubmitting(false)
            })
          }}
          validationSchema={this.schema}
        >
          {props => (
            <form
              className="car-with-driver__form"
              onSubmit={props.handleSubmit}
            >
              <div className="left">
                <Bullet number={1} text="เลือกจังหวัด" className="my-3">
                  <div className="row">
                    <div className="col-md-12 col-lg-7">
                      <select
                        onChange={e => {
                          props.setFieldValue('car_id', '')
                          props.setFieldValue('city_id', e.target.value)
                        }}
                        value={props.values.city_id}
                        className="form-control"
                      >
                        <option value="">กรุณาเลือกจังหวัด</option>
                        {cities.map(city => (
                          <option key={city.id} value={city.id}>
                            {city.name[prefix]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Bullet>
                <Bullet number={2} text="ระบุวันเดินทาง" className="my-3">
                  <div className="row flex-md-column flex-xl-row">
                    <div className="col-md-12 col-lg-12 col-xl-6 pb-md-3 pt-md-1 py-lg-0">
                      รับรถ
                      <div className="datetime__wrapper">
                        <DatePicker
                          focused={this.state.pickupDateFocused}
                          onClick={this.togglePickupDate}
                          onOutsideClick={this.togglePickupDate}
                          onMobileClose={this.togglePickupDate}
                          onMobileDateChange={this.onPickupDateChange}
                          onDateChange={this.onPickupDateChange}
                          isDayBlocked={item => {
                            return moment()
                              .subtract(1, 'day')
                              .isAfter(item)
                          }}
                          date={moment(props.values.pickup_date)}
                          placeholder="เลือกวันรับรถ"
                          mobilePlaceholder="เลือกวันรับรถ"
                        />
                        <TimePicker
                          mobileWidth={600}
                          value={
                            this._createTime().filter(
                              item => props.values.pickup_time === item.value,
                            )[0]
                          }
                          onValueChange={value => {
                            props.setFieldValue('pickup_time', value.value)
                            this.loadPricing({ pickup_time: value.value })
                            this.togglePickupTime()
                          }}
                          options={this.pickupTimeAvailable}
                          mobileOptions={this._generateGroupTime(
                            this.pickupTimeAvailable,
                          )}
                          focused={this.state.pickupTimeFocused}
                          onClick={this.togglePickupTime}
                          onClose={this.togglePickupTime}
                          placeholder="เลือกเวลา"
                          mobilePlaceholder="เลือกเวลารับรถ"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-6">
                      คืนรถ
                      <div className="datetime__wrapper">
                        <DatePicker
                          focused={this.state.returnDateFocused}
                          onClick={this.toggleReturnDate}
                          onOutsideClick={this.toggleReturnDate}
                          onMobileClose={this.toggleReturnDate}
                          onMobileDateChange={value => {
                            props.setFieldValue(
                              'return_date',
                              value.format('YYYY-MM-DD'),
                            )
                            this.loadPricing({
                              return_date: value.format('YYYY-MM-DD'),
                            })
                          }}
                          isDayBlocked={item => {
                            return moment(props.values.pickup_date).isAfter(
                              item,
                            )
                          }}
                          onDateChange={value => {
                            props.setFieldValue(
                              'return_date',
                              value.format('YYYY-MM-DD'),
                            )
                            this.loadPricing({
                              return_date: value.format('YYYY-MM-DD'),
                            })
                            this.setState({
                              returnDateFocused: false,
                            })
                          }}
                          date={moment(props.values.return_date)}
                          placeholder="เลือกวันคืนรถ"
                          mobilePlaceholder="เลือกวันคืนรถ"
                        />

                        <TimePicker
                          mobileWidth={600}
                          value={
                            this._createTime().filter(
                              item => props.values.return_time === item.value,
                            )[0]
                          }
                          onValueChange={value => {
                            props.setFieldValue('return_time', value.value)
                            this.loadPricing({ return_time: value.value })
                            this.toggleReturnTime()
                          }}
                          options={this.pickupTimeAvailable}
                          mobileOptions={this._generateGroupTime(
                            this.pickupTimeAvailable,
                          )}
                          focused={this.state.returnTimeFocused}
                          onClick={this.toggleReturnTime}
                          onClose={this.toggleReturnTime}
                          placeholder="เลือกเวลา"
                          mobilePlaceholder="เลือกเวลาคืนรถ"
                        />
                      </div>
                    </div>
                  </div>
                </Bullet>
                <Bullet number={3} text="รุ่นรถที่ต้องการ" className="my-3">
                  {props.values.city_id === '' && <Empty />}
                  {props.values.city_id !== '' && (
                    <div className="car-with-driver__wrapper">
                      {(
                        this.props.cities.find(
                          c => c.id + '' === props.values.city_id + '',
                        ) || { cars: [] }
                      ).cars.map(car => (
                        <Car
                          onClick={() => {
                            props.setFieldValue('car_id', car.id)
                            this.loadPricing({ car_id: car.id })
                          }}
                          key={car.id}
                          isSelected={props.values.car_id === car.id}
                          {...car}
                        />
                      ))}
                    </div>
                  )}
                </Bullet>
                <div>
                  <MediaQuery maxWidth={768}>
                    {matches => {
                      if (matches) {
                        return (
                          <div className="form__summary__wrapper--mobile">
                            <Summary {...this.state.pricing} />
                          </div>
                        )
                      } else {
                        return ''
                      }
                    }}
                  </MediaQuery>
                </div>
                <Bullet number={4} text="ข้อมูลติดต่อ" className="my-3">
                  <div className="row">
                    <div className="col-md-12 col-lg-8 col-xl-6 mb-3">
                      ชื่อ - สกุล {this.errorRender('full_name')}
                      <input
                        value={props.values.full_name}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="full_name"
                        className="form-control"
                        placeholder="กรุณากรอกชื่อของคุณ"
                      />
                    </div>
                    <div className="col-md-12 col-lg-8 col-xl-6 mb-3">
                      เบอร์โทรศัพท์ {this.errorRender('phone_number')}
                      <input
                        value={props.values.phone_number}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="phone_number"
                        type="tel"
                        className="form-control"
                        placeholder="กรุณากรอกเบอร์โทรศัพท์ของคุณ"
                      />
                    </div>
                    <div className="col-md-12 col-lg-8 col-xl-6 mb-3">
                      อีเมล {this.errorRender('email')}
                      <input
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="กรุณากรอกอีเมลของคุณ"
                      />
                    </div>
                  </div>
                </Bullet>
              </div>
              <MediaQuery minWidth={769}>
                <div>
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <SideBox
                      {...this.state.pricing}
                      isSubmitting={props.isSubmitting}
                      onOpenTerms={this.onOpenTerms}
                      onCloseTerms={this.onCloseTerms}
                      isLoadingPricing={this.state.isLoadingPricing}
                    />
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: 12,
                        marginTop: 15,
                        color: '#7D99B7',
                      }}
                    >
                      ติดปัญหาหรือมีข้อสงสัย โทร. 02-038-5222
                    </p>
                  </StickyBox>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={768}>
                <div className="car-with-driver__terms--mobile">
                  ข้าพเจ้าอ่านและได้ยอมรับ{' '}
                  <button
                    type="button"
                    className="readmore btn btn-link"
                    onClick={() => this.onOpenTerms()}
                  >
                    ข้อกำหนดและนโยบาย <i className="flaticon-out" />
                  </button>{' '}
                  ในการจองนี้
                </div>
                <Button
                  isLoadingPricing={this.state.isLoadingPricing}
                  isSubmitting={props.isSubmitting}
                />
              </MediaQuery>
            </form>
          )}
        </Formik>
        <Modal
          closable
          destroyOnClose
          visible={this.state.isOpenTerms}
          onClose={this.onCloseTerms}
          animation="fade"
          maskAnimation="fade"
        >
          <div className="pt-3 pb-3">
            <TermsAndConditions />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

const TermsAndConditions = () => {
  return (
    <div className="car-with-driver__terms">
      <h2>เงื่อนไข และข้อตกลง</h2>
      <ol>
        <li>
          <span>สิ่งที่รวมอยู่ในราคาเช่ารถ พร้อมคนขับ</span> <br />
          <p>
            ราคาเช่ารถตามจำนวนวันที่เลือก และค่าบริการคนขับเริ่มต้นวันละ 9
            ชั่วโมง (โดยจะต้องใช้เริ่มใช้และสิ้นสุดการใช้รถภายในช่วงเวลาทำการ
            8:00 ถึง 18:00 เท่านั้น)
          </p>
        </li>
        <li>
          <span>สิ่งที่ไม่รวมอยู่ในราคาเช่ารถ พร้อมคนขับ</span> <br />
          <p>
            ราคาที่แสดงนั้นจะยังไม่ครอบคลุมถึง ค่าน้ำมัน, ค่าทางด่วน (ถ้ามี),
            ค่าที่จอดรถ (ถ้ามี) , ค่าบริการกรณีมีให้บริการล่วงเวลา
            และค่าบริการกรณีมีการบริการนอกเวลา
            รวมไปถึงค่าที่พักพนักงานขับกรณีมีการค้างแรมนอกสถานที่
            โดยสามารถดูค่าบริการของการบริการต่างๆ จากตารางข้างล่าง
          </p>
          <table className="table table-bordered mb-0">
            <thead>
              <tr>
                <th scope="col">ประเภทของค่าบริการเพิ่มเติม</th>
                <th className="fee" scope="col">
                  ค่าบริการ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ค่าน้ำมัน (ตลอดการเดินทาง)</td>
                <td>ตามจริง</td>
              </tr>
              <tr>
                <td>ค่าทางด่วน (ถ้ามี)</td>
                <td>ตามจริง</td>
              </tr>
              <tr>
                <td>ค่าที่จอดรถ (ถ้ามี)</td>
                <td>ตามจริง</td>
              </tr>
              <tr>
                <td>
                  ค่าบริการกรณีให้บริการเกินระยะเวลาที่กำหนด (9 ชั่วโมง/วัน)
                </td>
                <td>100-400 บาท/ชม.</td>
              </tr>
              <tr>
                <td>
                  ค่าบริการกรณีเริ่มหรือสิ้นสุดการใช้บริการนอกเวลาทำการ
                  (8:00-18:00)
                </td>
                <td>100-400 บาท/ชม.</td>
              </tr>
              <tr>
                <td>ค่าที่พักของพนักงานขับรถ</td>
                <td>เริ่มต้น 400 บาท/คืน</td>
              </tr>
            </tbody>
          </table>
          <small>*หรือชำระค่าที่พักตามจริง</small>
        </li>
        <li>
          <span>ระยะเวลาในการให้บริการ </span> <br />
          <p>
            การเช่ารถพร้อมคนขับ มีระยะเวลาในการให้บริการ 9 ชั่วโมง
            โดยจะคิดเป็นการเช่า 1 วัน
            หากมีความจำเป็นต้องใช้บริการเกินระยะเวลาที่กำหนด
            สามารถชำระค่านอกเวลาให้บริการ (OT สำหรับผู้ขับ)
            เพิ่มเติมได้โดยตรงกับพนักงานขับรถตามอัตราที่ทางผู้ให้เช่าเป็นผู้กำหนด
          </p>
        </li>
        <li>
          <span>การมัดจำและการชำระเงิน</span> <br />
          <p>
            หลังทำการจอง ผู้เช่าจะต้องชำระค่าล็อคคิวรถเป็นจำนวนเงิน 500-1,000
            บาท (โดยอัตราดังกล่าวขึ้นอยู่กับผู้ให้เช่าเป็นผู้กำหนด)
            หากทำการจองในช่วงฤดูกาลท่องเที่ยว (เดือนตุลาคมถึงเดือนกุมภาพันธ์)
            หรือช่วงเทศกาลและวันหยุดนักขัตฤกษ์
            ผู้ให้เช่าอาจมีการเรียกเก็บค่าล็อคคิวรถที่เพิ่มขึ้นจากอัตราดังกล่าว
            โดยค่าล็อคคิวรถจะถูกนำไปหักจากราคาเช่ารถ
            ซึ่งในวันรับรถผู้เช่าชำระเพียงค่าเช่าส่วนที่เหลือ
          </p>
        </li>
        <li>
          <span>การยกเลิก</span> <br />
          <p>
            หากต้องการยกเลิกการจอง
            ผู้เช่าสามารถแจ้งความประสงค์กับทางผู้ให้เช่าได้โดยตรง
            โดยจะได้รับเงินคืนก็ต่อเมื่อผู้เช่าได้ทำการแจ้งยกเลิกล่วงหน้าอย่างน้อย
            72 ชั่วโมง (โดยนับจากวันและเวลาที่เริ่มการเช่า)
            หากไม่เข้าเงื่อนไขดังกล่าวผู้ให้เช่าขอสงวนสิทธิ์ในการคืนค่าล็อคคิวรถทุกกรณี
          </p>
        </li>
        <li>
          <span>ความเสียหายภายในตัวรถ</span> <br />
          <p>
            หากมีความเสียหายเกิดขึ้นภายในตัวรถอันเกิดจากการกระทำของผู้เช่า อาทิ
            อุปกรณ์ในรถยนต์ เบาะรถยนต์ ชำรุดหรือสูญหาย ในระหว่างการใช้บริการ
            ทางผู้เช่าจะต้องจ่ายค่าปรับทั้งหมดตามรายการที่ผู้ให้เช่าเรียกเก็บ
            หรือยึดตามเกณฑ์ราคาค่าปรับที่ศูนย์บริการรถยนต์ยี่ห้อนั้นๆกำหนดขึ้นและค่าบริการตามจริง
          </p>
        </li>
        <li>
          <span>ทรัพย์สินสูญหาย</span> <br />{' '}
          <p>
            ผู้ให้เช่าไม่มีความรับผิดชอบใดๆ
            ในกรณีที่ผู้เช่าลืมทรัพย์สินไว้ในรถที่เช่าทั้งสิ้น
            หลังการเดินทางเสร็จสิ้นแล้ว
            ไดรฟ์ฮับขอสงวนสิทธิ์ในการไม่รับผิดชอบความเสียหายหรือสูญหายของทรัพย์สินที่เกิดขึ้นจากความประมาทของผู้โดยสารที่ทำทรัพย์สินหล่นหรือลืมไว้ในรถของคนผู้ให้เช่า
          </p>
        </li>
      </ol>
    </div>
  )
}

/**
 * @param  {Number} props.number
 * @param  {String} props.text
 */
const Bullet = ({ number, text, children, className }) => (
  <React.Fragment>
    <div className="row col-12 align-items-center">
      <span className="circle-bullet">{number}</span>
      <span className="circle-title">{text}</span>
    </div>
    <div className={`bullet--content ${className ? className : ''}`}>
      {children}
    </div>
  </React.Fragment>
)

const Car = ({ image, price_per_day, title, isSelected, onClick }) => (
  <div
    className={`car-with-driver__car ${isSelected ? 'active' : ''}`}
    onClick={onClick}
  >
    <img src={image} alt={title.th} />
    <h3>{title.th}</h3>
    <section>ราคา {price_per_day.toLocaleString()} / วัน</section>
  </div>
)
/**
 * @param  {object} props
 * @param  {string} props.pickup_datetime
 * @param  {string} props.return_datetime
 * @param  {number} props.duration_days
 * @param  {number} props.duration_hours
 * @param  {number} props.pickup_return_delivery
 * @param  {number} props.total_price
 * @param  {string} props.city
 * @param  {function} props.onOpenTerms
 * @param  {boolean} props.isLoadingPricing
 */
export const SideBox = ({
  pickup_datetime = moment().format('YYYY-MM-DD 10:00'),
  return_datetime = moment()
    .add(1, 'days')
    .format('YYYY-MM-DD 10:00'),
  duration_days = 1,
  duration_hours = 1,
  pickup_return_delivery = 0,
  total_price = 999,
  city = 'KrungTEP',
  isSubmitting = false,
  isLoadingPricing = false,
  onOpenTerms,
}) => (
  <div className="form__sidebox">
    <div className="form__sidebox--pair">
      <div>
        <label>รับรถ/คืนรถที่</label>
        <div>{city}</div>
      </div>
      <div>
        <label>วันเดินทาง</label>
        <div>
          {moment(pickup_datetime).format('DD MMM YYYY HH:mm')} -{' '}
          {moment(return_datetime).format('DD MMM YYYY HH:mm')}
        </div>
      </div>
    </div>
    <Summary
      duration_days={duration_days}
      duration_hours={duration_hours}
      pickup_return_delivery={pickup_return_delivery}
      total_price={total_price}
    />
    <div>
      <div className="upperline">
        ข้าพเจ้าอ่านและได้ยอมรับ
        <button
          type="button"
          className="readmore btn btn-link"
          onClick={() => onOpenTerms()}
        >
          ข้อกำหนดและนโยบาย <i className="flaticon-out" />
        </button>{' '}
        ในการจองนี้
      </div>
      <Button isLoadingPricing={isLoadingPricing} isSubmitting={isSubmitting} />
    </div>
  </div>
)

const Button = ({ isSubmitting = false, isLoadingPricing = false }) => (
  <button
    type="submit"
    className="btn btn-primary btn-large btn-block"
    disabled={isSubmitting || isLoadingPricing}
  >
    {isSubmitting && (
      <span
        className="spinner-grow spinner-grow-sm"
        role="status"
        aria-hidden="true"
      />
    )}{' '}
    {isSubmitting
      ? 'กำลังทำการจอง...'
      : isLoadingPricing
      ? 'กำลังคำนวนราคา...'
      : 'ทำการจอง'}
  </button>
)

/**
 * @param  {object} props
 * @param  {number} props.duration_days
 * @param  {number} props.duration_hours
 * @param  {number} props.pickup_return_delivery
 * @param  {number} props.total_price
 */
export const Summary = ({
  duration_days = 1,
  duration_hours = 0,
  pickup_return_delivery = 0,
  total_price = 0,
}) => (
  <div className="form__summary">
    <div className="underline">
      <span>รวมค่าเช่ารถสำหรับ {duration_days} วัน</span>
      <span>฿{total_price.toLocaleString()}</span>
    </div>
    <div className="underline">
      <span>ค่ารับ-ส่งรถ</span>
      <span className="free">ฟรี</span>
    </div>
    <div className="underline bold">
      <span>ราคารวม</span>
      <span>฿{total_price.toLocaleString()}</span>
    </div>
    <div style={{ marginTop: 15 }}>
      <div>
        <b>อัตราข้างต้นรวม</b>
      </div>
      <div className="value-props">
        <div>
          <img style={{ marginTop: 4 }} src={carIcon} alt="rental car" />
          <div>รถเช่า</div>
        </div>
        <div>
          <img src={driverIcon} alt="car with driver" />
          <div>คนขับ</div>
        </div>
        <div>
          <img src={nineIcon} alt="บริการ 9ชม/วัน" />
          <div>บริการ 9ชม/วัน</div>
        </div>
      </div>
    </div>
    <div>
      <div className="except">
        <b>อัตราข้างต้นนี้ไม่รวม</b> ค่าบริการนอกเวลาทำการ, ค่าน้ำมัน
        และค่าผ่านทางพิเศษ
      </div>
    </div>
  </div>
)

const Empty = () => (
  <div className="empty">
    <img src={clickIcon} alt="โปรดเลือกจังหวัดและระบุวันเดินทางเพื่อเลือกรถ" />
    <p>โปรดเลือกจังหวัดและระบุวันเดินทางเพื่อเลือกรถ</p>
  </div>
)
