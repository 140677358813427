import React from 'react'
import './success.scss'
import icon from '../../../static/assets/car-with-driver/file.svg'

/**
 * @param  {object} props
 * @param  {string} props.bookingId
 */

const Success = ({ bookingId }) => (
  <div className="car-with-driver__success">
    <div>
      <div>
        <img src={icon} alt="sucess" />
        <p className="request-success__header">ระบบได้รับคำขอจองของคุณแล้ว!</p>
        <p>
          หมายเลขการจองของคุณคือ #D{bookingId} <br /> <br />
          กรุณารอการติดต่อกลับจากเจ้าหน้าที่เพื่อยืนยันการจองภายใน 15-30 นาที
          <br />
          (เวลาทำการ 10:00น. - 18:00น.
          นอกเหนือเวลาทำการเจ้าหน้าที่จะติดต่อกลับภายในวันทำการถัดไป)
        </p>
      </div>
    </div>
    <div>
      <p>หากติดปัญหาหรือมีข้อสงสัย โทร. 02-038-5222</p>
    </div>
  </div>
)

export default Success
